<div  class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Manage all of your Stuff Using a Apzie App</h1>
                            <p>App ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Start free trial <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-form">
                            <h3>Get started for Free</h3>
                                
                            <form>
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" placeholder="Name">
                                </div>
    
                                <div class="form-group mb-3">
                                    <input type="email" class="form-control" placeholder="Email">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" placeholder="Subjects">
                                </div>
    
                                <div class="form-group mb-3">
                                    <textarea cols="30" rows="6" class="form-control" placeholder="Message"></textarea>
                                </div>
    
                                <button type="submit">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section>

<section id="about" class="about-area pb-100">
    <app-about></app-about>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section id="features" class="features-area pb-70">
    <app-features></app-features>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<app-app-screenshots></app-app-screenshots>

<section class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <app-overview></app-overview>
</section>

<section class="pricing-area pt-100 pb-70">
    <app-pricing></app-pricing>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section>

<app-app-download></app-app-download>

<section id="blog" class="blog-area pt-100 pb-70">
    <app-blog></app-blog>
</section>

<section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>

    <div class="default-shape">
        
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<app-subscribe></app-subscribe>