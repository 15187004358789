<section id="screenshots" class="screenshot-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Aceites Escenciales Mas Usados</h2>
            <div class="bar"></div>
            <p>A continuacion te presentamos los aceites mas usados por sus propiedades, te invitamos a conocer mas de ellos</p>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme">
            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/1.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/2.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/3.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/4.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/5.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/6.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/1.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/2.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/3.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/4.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/5.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/6.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>