<div class="subscribe-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="subscribe-content">
                    <h2>Suscribete para obtener informacion sobre eventos</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Ingresa tu Email" name="EMAIL" required autocomplete="off">
                    <button type="submit">Subscribirse</button>
                </form>
            </div>
        </div>
    </div>
</div>