<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <div class="logo">
            <img src="../../../../assets/img/logo.jpg" style="height: 120px;">
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item"><a href="#home" class="nav-link">Inicio</a></li>
                <li class="nav-item"><a href="#about" class="nav-link">Acerca de mi</a></li>
                <li class="nav-item"><a href="#features" class="nav-link">Features</a></li>
                <li class="nav-item"><a href="#screenshots" class="nav-link">Aceites Escenciales</a></li>
                <li class="nav-item"><a href="#faq" class="nav-link">FAQ</a></li>
                <li class="nav-item"><a href="#blog" class="nav-link">Educacion Continua</a></li>
                <li class="nav-item"><a href="#contact" class="nav-link">Contacto</a></li>
            </ul>
        </div>
    </div>
</nav>