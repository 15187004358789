<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-users"></i>
                </div>
                <h3><span>250+</span></h3>
                <p>Miembros de la comunidad</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-heart"></i>
                </div>
                <h3><span>1000+</span></h3>
                <p>Personas compartiendo bienestar</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-star"></i>
                </div>
                <h3><span>+10</span></h3>
                <p>Beneficios exclusivos OilyMother</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-download"></i>
                </div>
                <h3><span>90+</span></h3>
                <p>Eventos para inscritos</p>
                <br/>
            </div>
        </div>
    </div>
</div>